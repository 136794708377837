import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material-nextjs@6.1.6_@emotion+cache@11.13.1_@emotion+react@11.13.3_react@19.0.0-rc-69d4_ryixrz73m2dhimd7cnkemdzrka/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/vercel/path0/node_modules/.pnpm/next-view-transitions@0.3.2_next@15.0.1_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plu_7dx6cmqiqlv4qriogjnn2e4ngy/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["BaseLayout"] */ "/vercel/path0/src/app/base-layout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AppTheme"] */ "/vercel/path0/src/domain/app/app-theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Translation"] */ "/vercel/path0/src/domain/app/intl/translation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/vercel/path0/src/domain/app/store/store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreviousPathnameProvider"] */ "/vercel/path0/src/domain/shared/lib/use-previous-path.tsx");
