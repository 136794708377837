"use client";

import { store } from "./store.js";
import { StoreContext } from "./store-context.js";

export function StoreProvider({ children }: React.PropsWithChildren) {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}
