"use client";

import BottomNavigation, {
  type BottomNavigationProps,
} from "@mui/material/BottomNavigation";
import { useTheme } from "@mui/material/styles";

export const NavigationRail: React.FC<BottomNavigationProps> = ({
  ...props
}) => {
  const theme = useTheme();

  return (
    <BottomNavigation
      {...props}
      sx={{
        alignItems: "center",
        position: "fixed",
        height: "calc(72px + env(safe-area-inset-bottom))",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 2,
        [theme.breakpoints.up("md")]: {
          flexDirection: "column",
          justifyContent: "flex-start",
          width: 80,
          height: "100vh",
          padding: 0,
          top: 0,
          bottom: "unset",
          left: 0,
          borderRight: `1px solid ${theme.palette.divider}`,
        },
      }}
    />
  );
};
