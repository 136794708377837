"use client";

import BottomNavigationAction, {
  type BottomNavigationActionProps,
} from "@mui/material/BottomNavigationAction";
import { Link } from "next-view-transitions";
import { memo } from "react";

interface NavigationRailActionProps extends BottomNavigationActionProps {}

export const NavigationRailAction: React.FC<NavigationRailActionProps> = memo(
  ({ value, ...props }) => (
    <BottomNavigationAction
      component={Link}
      href={value}
      value={value}
      replace={true}
      {...props}
      sx={(theme) => ({
        [theme.breakpoints.up("md")]: {
          maxWidth: 72,
          minWidth: 72,
          minHeight: 72,
          maxHeight: 72,
        },
        padding: "14px 8px",
        paddingLeft: "9.5px",
        "&.MuiBottomNavigationAction-iconOnly": {
          paddingTop: "30px",
          paddingBottom: "10px",
        },
        "&.Mui-selected": {
          paddingTop: "0px",
        },
        label: {
          paddingTop: "4px",
          "&.Mui-selected": {
            fontSize: (theme) => theme.typography.pxToRem(14),
          },
        },
      })}
    />
  ),
);
