"use client";

import { type IntlConfig, IntlProvider } from "react-intl";

interface TranslationProviderProps {
  readonly messages: IntlConfig["messages"];
}

export function Translation({
  messages,
  children,
}: React.PropsWithChildren<TranslationProviderProps>) {
  return (
    // @ts-expect-error Wrong typing
    <IntlProvider locale={`ru`} messages={messages}>
      {/* @ts-expect-error Wrong typing */}
      {children}
    </IntlProvider>
  );
}
